import { Card, CardContent, Typography } from '@mui/material'
import Lottie from 'lottie-react'
import React, { useEffect } from 'react'
import Success from '../PaymentSuccessAnimation.json'
import useLocales from 'src/hooks/useLocales.js'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
function PaymentSuccess() {
    const { translate } = useLocales()
    const query = useQueryClient()
    const navigate = useNavigate()
    const user = useSelector(state => state.user.user)

    useEffect(() => {
        query.refetchQueries({ queryKey: ['subscriptions'] })

        setTimeout(() => {
            navigate('/settings/account?tab=settings.subscriptions')
        }, 3 * 1000)
    }, [user?.id])

    return (
        <Card>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column' }}>
                <Lottie loop={false} animationData={Success} style={{ width: '300px' }}></Lottie>
                <Typography variant='h6' textAlign={'center'}>{translate('subscription.paymentSuccess')}</Typography>
                <Typography variant='caption' mt={2} color={'GrayText'}>{translate('subscription.redirect')}</Typography>
            </CardContent>
        </Card>
    )
}

export default PaymentSuccess