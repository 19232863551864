import axios from "axios"
import { useMutation } from "react-query"
import useLocales from "./useLocales";
import { useSnackbar } from "notistack";

export const useLinkDownload = () => {
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar();

    const linkDownload = useMutation((link) => axios.get(link), { retry: 10, retryDelay: 1000 })


    const downloadByLink = async (url, msg) => {
        try {
            await linkDownload.mutateAsync(url)
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = url;
            document.body.appendChild(link);
            link.click();
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);

            enqueueSnackbar(translate(msg), { variant: 'success' })

        } catch (error) {
            enqueueSnackbar(translate('general.error'), {
                variant: 'error'
            })
        }
    }

    return {
        downloadByLink,
        isDownloading:linkDownload.isLoading
    }
}