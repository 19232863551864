import { Chip, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { get, isEmpty } from 'lodash'
import DatagridOptions from 'src/components/dashboard/DatagridOptions';
import { gridKeys } from 'src/enums/dashboard.enums';
import { formatDate, getOutils, getOutilsColor } from './dashboard';
import { ExamExtractor } from './ExamExtractor';
import ShowMore from 'src/components/global/ShowMore';
import { store } from 'src/redux/store';
import { setExamCases, setExamCasesModal, setSelectedCasesHorse } from 'src/redux/slices/examen';
import { t } from 'i18next';


//Uncategorized Exams
export const dateComparator = (date1, date2) => {
    return dayjs(date1, "DD/MM/YYYY HH:mm").isAfter(dayjs(date2, "DD/MM/YYYY HH:mm")) ? 1 : -1
}

export const dateComparatorDesc = (date1, date2) => {
    return dayjs(date1, "DD/MM/YYYY HH:mm").isBefore(dayjs(date2, "DD/MM/YYYY HH:mm")) ? 1 : -1
}

export const uncategorizedExamsColumns = [
    { field: gridKeys.horse, headerName: gridKeys.horse, flex: 1, valueGetter: (params) => ExamExtractor.getHorseName(params.row), sortable: false, filterable: false },
    { field: gridKeys.client, headerName: gridKeys.client, flex: 1, valueGetter: (params) => ExamExtractor.getHorseOwner(params.row), sortable: false, filterable: false },
    {
        field: gridKeys.tool, headerName: gridKeys.lastUsedTool, flex: 1, renderCell: (params) => {
            let outil = getOutils(get(params, 'row.@type', null))
            let colorOutils = getOutilsColor(get(params, 'row.@type', null))
            return <Chip label={outil} sx={{ backgroundColor: colorOutils.background, color: colorOutils.text, borderRadius: 1 }} size='small' />
        }, sortable: false, filterable: false
    },
    { field: gridKeys.description, headerName: gridKeys.description, flex: 1, valueGetter: (params) => ExamExtractor.getDescription(params.row), sortable: false, filterable: false },

    { field: gridKeys.creation, headerName: gridKeys.creation, flex: 1, type: 'date', sortable: false, filterable: false, valueGetter: (params) => ExamExtractor.getLastExam(params.row) },
    {
        field: gridKeys.folders, headerName: gridKeys.folders, flex: 1, sortable: false, filterable: false, renderCell: (params) => {
            let folders = params.row?.exam_in_vet_cases.map(cases => cases?.vet_case?.case_number ?? '')
            return <span onClick={(e) => {
                e.stopPropagation()
                store.dispatch(setExamCases(params.row?.exam_in_vet_cases.map(e => e.vet_case)))
                store.dispatch(setSelectedCasesHorse(params.row?.horse))
                store.dispatch(setExamCasesModal(true))
            }}>
                <ShowMore items={folders} maxLength={3} renderItem={(item) => <Chip size='small' variant='filled' color='default' label={item}></Chip>}></ShowMore ></span>
        }
    },
    { field: gridKeys.actions, headerName: gridKeys.actions, width: 200, renderCell: (params) => <DatagridOptions inlineOptions isExam isVetCase={false} item={params.row} />, sortable: false, filterable: false }
];

//Filter fields for vet cases
export const uncategorizedExamsFilters = [
    { field: gridKeys.horse, headerName: gridKeys.horse, data: [], value: '', path: 'horse.name' },
    { field: gridKeys.client, headerName: gridKeys.client, data: [], value: '', path: 'horse.owner' },
    { field: gridKeys.tool, headerName: gridKeys.tool, data: [], value: '', path: '@type' },
    { field: gridKeys.creation, headerName: gridKeys.date, value: '', path: 'timestamp_start.unix', hidden: true },
];



//Dashboard vet cases exams view


export const vetCaseExamsColumns = [
    { field: gridKeys.creation, headerName: gridKeys.creation, type: 'date', sortComparator: dateComparator, flex: 1, valueGetter: (params) => ExamExtractor.getLastExam(params.row), sortable: true, filterable: false },
    { field: gridKeys.description, headerName: gridKeys.description, flex: 1, valueGetter: (params) => ExamExtractor.getDescription(params.row), sortable: false, filterable: false },
    {
        field:gridKeys.review, headerName: gridKeys.review, flex: 1, renderCell: (params) => {

            let reviews = params.row?.ecg_reviews ?? []
            let reviewd = !isEmpty(reviews)
            let reviewer = get(reviews, '[0].reviewed_by', '')
            let date = get(reviews, '[0].review_date') ? dayjs(get(reviews, '[0].review_date')).format('DD-MM-YYYY ') : ''
            return (
                <Tooltip placement='top' title={reviewd ? t('ecg.reviewed', {
                    user: reviewer?.full_name,
                    date: date
                }) : ''} arrow>
                    <Chip
                        size='small'
                        variant='filled'
                        label={reviewd ? t('reviewed') : t('notReviewed')}
                        sx={{ borderRadius: 1, backgroundColor: reviewd ? 'rgba(135, 211, 124,0.8)' : 'rgba(241, 130, 141)', color: 'white' }}
                    />
                </Tooltip>)
        }
    },

    {
        field: gridKeys.tool, headerName: gridKeys.tool, flex: 1, renderCell: (params) => {
            let outil = getOutils(get(params, 'row.@type', null))
            let colorOutils = getOutilsColor(get(params, 'row.@type', null))
            return <Chip label={outil} sx={{ backgroundColor: colorOutils.background, color: colorOutils.text, borderRadius: 1 }} size='small' />
        }, sortable: false, filterable: false
    },
    { field: gridKeys.client, headerName: gridKeys.client, flex: 1, valueGetter: (params) => ExamExtractor.getHorseOwner(params.row), sortable: false, filterable: false },


    { field: gridKeys.actions, headerName: gridKeys.actions, renderCell: (params) => <DatagridOptions showDelete isExam isVetCase={false} item={params.row} />, sortable: false, filterable: false }
];

//Filter fields for vet cases
export const vetCaseExamsFilters = [
    { field: gridKeys.client, headerName: gridKeys.client, data: [], value: '', path: 'horse.owner' },
    { field: gridKeys.tool, headerName: gridKeys.tool, data: [], value: '', path: '@type' },
    { field: gridKeys.creation, headerName: gridKeys.date, value: '', path: 'timestamp_start.unix', hidden: true },
];