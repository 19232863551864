import { get, isEmpty } from "lodash"
import { useMemo } from "react"
import { useMutation, useQuery } from "react-query"
import { useSelector } from "react-redux"
import { Subscription } from "src/classes/Subscription"
import { formatCreationDate, formatDate } from "src/utils/dashboard"
import { dateComparatorDesc } from "src/utils/examDashboard"

export const useSubscription = ({ enableQuery, user_email, user_lang, user_id, enableSubscriptionsQuery }) => {
    const user = useSelector(state => state.user.user)

    const billingQuery = useQuery(['billings'], () => Subscription.getBillingInfos(user_email, user_lang), {
        enabled: enableQuery,
    })

    const userSubscriptions = useQuery(['subscriptions'], () =>{
        
        return Subscription.getUserSubscriptions(user_id)}, {
        enabled: enableSubscriptionsQuery,
    })

    const userSubs = get(userSubscriptions.data, 'data.hydra:member', [])

    const subscriptions = useMemo(() => {
        if (isEmpty(userSubs)) return null


        let sorted = [...userSubs].sort((a, b) => dateComparatorDesc(
            formatCreationDate(get(a, 'creation_date', null))
            , formatCreationDate(get(b, 'creation_date', null)
            )))

        return sorted.at(0)


    }, [userSubs])

    const sendSubscription = useMutation((data) => Subscription.sendSubscription(data))
    const getSubscriptionById = useMutation((id) => Subscription.getSubscriptionById(id), {
        retry: (failureCount, error) => {
            // Retry if the error is due to missing link
            if (error.message === 'Link not found in response') {
                return true; // Retry on missing link
            }
            return false; // Don't retry for other errors
        }, retryDelay: 10 * 1000
    })

    const getSubscriptionByIdForPayment = useMutation((id) => Subscription.getSubscriptionByIdForPayment(id), {
        retry: (failureCount, error) => {
            // Retry if the error is due to missing link
            if (error.message === 'Not paid yet') {
                return true; // Retry on missing link
            }
            return false; // Don't retry for other errors
        }, retryDelay: 2 * 1000
    })


    return {
        isLoadingPlans: billingQuery.isLoading,
        isErrorPlans: billingQuery.isError,
        plans: get(billingQuery.data, 'data.products', []),
        userData: get(billingQuery.data, 'data'),
        sendSubscription,
        getSubscriptionById,
        getSubscriptionByIdForPayment,
        subscriptions,
        loadingSubscriptions: userSubscriptions.isLoading
    }
}