import React, { useMemo, useState } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, Box, ListItemIcon, Button } from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';
import Delete from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility';
import Edit from '@mui/icons-material/Edit';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useNavigate } from 'react-router';
import DeleteModal from './DeleteModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteExamInVetCase, deleteVetCase, getVetCases, getVetCasesRestricted } from 'src/redux/slices/examen';
import { useSnackbar } from 'notistack';
import useLocales from 'src/hooks/useLocales';
import { useEffect } from 'react';
import UpdateCaseModal from './UpdateCaseModal';
import { Examen } from 'src/classes/Examen';
import { useSelector } from 'react-redux';
import { get, isEmpty, isNull } from 'lodash';
import { CreateNewFolder, CreateNewFolderOutlined, DriveFileRenameOutline, SaveAltOutlined } from '@mui/icons-material';
import DownloadReportModal from './DownloadReportModal';
import AddExamToCase from './AddExamToCase';
import { ExamExtractor } from 'src/utils/ExamExtractor';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useLinkDownload } from 'src/hooks/useLinkDownload';


DatagridOptions.defaultProps = {
  inlineOptions: false,
  showDelete: false,
  enableEdit: false,

}

function DatagridOptions({ enableEdit, item, isVetCase, isExam, inlineOptions, showDelete }) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [deleteModal, setDeleteModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [downloadModal, setDownloadModal] = useState(false)
  const [addToCaseModal, setAddToCaseModal] = useState(false)
  const [vetCases, setVetCases] = useState([])

  const [refetchLoading, setRefetchLoading] = useState(false)
  const { translate } = useLocales()

  const { enqueueSnackbar } = useSnackbar();

  const { userInOrganisation } = useSelector((state) => state.user);

  let org_id = get(userInOrganisation, 'organisation.id', null)
  let user_org_id = get(userInOrganisation, 'id', null)
  let id = get(userInOrganisation, 'restricted', false) ? user_org_id : org_id
  //Mutations
  const deleteCaseMutation = useMutation(id => deleteVetCase(id))
  const deleteExamInCaseMutation = useMutation(id => deleteExamInVetCase(id))
  const updateCaseMutation = useMutation(({ data, id }) => Examen.updateCase(data, id))
  const addExamMutation = useMutation((data) => Examen.addExamToVetCase(data))
  const downloadVetCaseLabels = useMutation((data) => Examen.downloadVetCaseLabels(item?.id))
  const { downloadByLink, isDownloading } = useLinkDownload()
  const horse_id = isVetCase && get(item, 'horse.external_id')

  const query = useQueryClient()

  const canBeAddedToVetCase = useMemo(() => {
    return isExam && isEmpty(item?.exam_in_vet_cases)
  }, [isExam, item])

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleDeleteCase = () => {
    deleteCaseMutation.mutateAsync(item?.id)
      .then(async (result) => {
        //Afficher un toast si le dossier est supprimé
        enqueueSnackbar(translate(config.deleted), {
          variant: 'success'
        })

        //Refetch vet cases 
        setRefetchLoading(true)
        query.refetchQueries(['vet_cases', user_org_id])
        query.refetchQueries(['vet_case_exams'])
        query.refetchQueries(['vet_case', horse_id])

        setRefetchLoading(false)

        //Fermer le modal
        setDeleteModal(false)
      }).catch(error => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })
  }

  const handleDeleteExam = () => {
    deleteExamInCaseMutation.mutateAsync(item?.relation_id)
      .then(async (result) => {

        //Afficher un toast si l'examen est supprimé
        enqueueSnackbar(translate(config.deleted), {
          variant: 'success'
        })

        //Refetch vet cases 
        setRefetchLoading(true)
        query.refetchQueries(['exams', org_id])
        query.refetchQueries(['vet_case_exams'])

        setRefetchLoading(false)

        //Fermer le modal
        setDeleteModal(false)
      }).catch(error => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })
  }

  const addExamToCase = (caseId) => {
    addExamMutation.mutateAsync({
      'vet_case': caseId,
      'exam': get(item, '@id')
    }).then(res => {
      enqueueSnackbar(translate('snackbar.caseExamCreateSuccess'))
      query.refetchQueries(['exams', id])
      query.invalidateQueries({ queryKey: ['vet_cases'] })
      setAddToCaseModal(false)
    }).catch(err => {
      enqueueSnackbar(translate('general.error'), { variant: 'error' })

    })
  }

  const skipAddToVet = (vetCase) => {
    query.invalidateQueries(['exams', id])
    query.invalidateQueries({ queryKey: ['vet_cases'] })
    setAddToCaseModal(false)
  }

  const draftRepport = useMemo(() => {
    if (isVetCase) {
      return item?.reports?.find(e => !e.published)
    }
  }, [item])

  const publishedRepport = useMemo(() => {
    if (isVetCase) {
      return item?.reports?.filter(e => e.published)
    } else {
      return []
    }
  }, [item])



  const config = useMemo(() => {
    if (isVetCase) {
      return {
        title: "modal.removeCase",
        subtitle: "modal.removeCaseConfirm",
        deleted: "snackbar.caseRemoveSuccess",
        action: handleDeleteCase,
        redirect: function (item) {
          return navigate(PATH_DASHBOARD.horses.folderView(
            item.horse.external_id,
            item.id
          ))
        }
      }
    }
    if (isExam) {
      return {
        title: "modal.removeExam",
        subtitle: "modal.removeExamConfirm",
        deleted: "snackbar.examRemoveSuccess",
        action: handleDeleteExam,
        redirect: function (item) {

          let examType = ExamExtractor.getExamType(item)
          let horse = ExamExtractor.getHorseId(item)
          let externalId = item.external_id


          if (examType === 'Equisym') {
            navigate(PATH_DASHBOARD.horses.examenView(
              horse,
              externalId,
              item?.case_id,
            ))
          }

          if (examType === 'Equimetre') {
            navigate(PATH_DASHBOARD.horses.examenEquimetreView(
              horse,
              externalId,
              item?.case_id,
            ))
          }
        }
      }
    }
  }, [item])

  const updateCase = (data, id) => {

    updateCaseMutation.mutateAsync({ data, id })
      .then(res => {
        enqueueSnackbar(translate('snackbar.updateSuccess'), {
          variant: 'success'
        })
        query.refetchQueries(['vet_cases', user_org_id])
        query.refetchQueries(['vet_case', item?.horse.external_id])
      })
      .catch(err => {
        enqueueSnackbar(translate('general.error'), {
          variant: 'error'
        })
      })
      .finally(() => {
        setUpdateModal(false)

      })
  }

  const downloadEcgLabels = async () => {
    try {
      enqueueSnackbar(translate('examVideo:downloading'), { variant: 'success', autoHideDuration: 3600 })
      let { data } = await downloadVetCaseLabels.mutateAsync()

      if ('download_url' in data) {
        await downloadByLink(data.download_url, 'snackbar.labelsExported')
      }

    } catch (error) {
      enqueueSnackbar(translate('general.error'), {
        variant: 'error'
      })
    }
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>


      {/* Modals */}
      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        title={translate(config.title)}
        subTitle={translate(config.subtitle)}
        onCancel={() => setDeleteModal(false)}
        onDelete={config.action}
        loading={deleteCaseMutation.isLoading || deleteExamInCaseMutation.isLoading || refetchLoading}
      />

      {isVetCase &&
        <UpdateCaseModal
          open={updateModal}
          handleClose={() => setUpdateModal(false)}
          onCancel={() => setUpdateModal(false)}
          item={item}
          onUpdate={updateCase}
          loading={updateCaseMutation.isLoading}
        />
      }

      <AddExamToCase
        open={addToCaseModal}
        handleClose={() => setAddToCaseModal(false)}
        items={vetCases}
        loading={addExamMutation.isLoading}
        onCancel={() => setAddToCaseModal(false)}
        title={translate('modal.addToCase')}
        handleSubmit={addExamToCase}
        item={item}
        defaultHorse={ExamExtractor.getHorse(item)}
        skipAddToVet={skipAddToVet}
      >

      </AddExamToCase >

      <DownloadReportModal
        open={downloadModal}
        handleClose={() => setDownloadModal(false)}
        onCancel={() => setDownloadModal(false)}
        title={translate('gridOptions.downloadRepport')}
        items={publishedRepport}
      />
      {/* End Modals */}

      <Box >
        {
          inlineOptions ?

            <Box display={'flex'} flexDirection={'column'} my={1}>
              <Button size='small' onClick={() => config.redirect(item)}>
                {translate('gridOptions.view')}
              </Button>
              {
                canBeAddedToVetCase &&
                <Button size='small' onClick={() => setAddToCaseModal(true)} color='success'>
                  {translate('modal.addToCase')}
                </Button>
              }
            </Box>

            : <>
              <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ float: 'right' }}
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => config.redirect(item)} sx={{ gap: 1 }}>
                  <Visibility fontSize="small" color='info' />
                  {translate('gridOptions.view')}
                </MenuItem>

                {
                  !isEmpty(publishedRepport) &&
                  <MenuItem onClick={() => setDownloadModal(true)} sx={{ gap: 1 }}>
                    <SaveAltOutlined fontSize="small" color='success' />
                    {translate('gridOptions.downloadRepport')}
                  </MenuItem>
                }


                {
                  draftRepport &&
                  <MenuItem onClick={() => navigate(`/report/${draftRepport?.id}/horse/${item?.horse?.external_id}`)} sx={{ gap: 1 }}>
                    <DriveFileRenameOutline fontSize="small" color='warning' />
                    {translate('gridOptions.updateDraft')}
                  </MenuItem>
                }

                {
                  canBeAddedToVetCase &&
                  <MenuItem onClick={() => setAddToCaseModal(true)} sx={{ gap: 1 }}>
                    <CreateNewFolder fontSize="small" color='success' />
                    {translate('modal.addToCase')}
                  </MenuItem>
                }
                {
                  isVetCase && <MenuItem
                    disabled={downloadVetCaseLabels.isLoading || isDownloading}
                    onClick={downloadEcgLabels} sx={{ gap: 1 }}>
                    <Icon fontSize={20} color='lime' icon='bx:export' />
                    {translate('exportLabels')}
                  </MenuItem>
                }

                {!get(userInOrganisation, 'restricted', false) &&
                  <span>{
                    isVetCase && enableEdit && <MenuItem onClick={() => setUpdateModal(true)} sx={{ gap: 1 }}>
                      <Edit fontSize="small" color='warning' />
                      {translate('gridOptions.update')}
                    </MenuItem>
                  }
                    {
                      (isVetCase || showDelete) && enableEdit && <MenuItem onClick={
                        () => setDeleteModal(true)
                      } sx={{ gap: 1 }}>
                        <Delete fontSize="small" color='error' />
                        {translate('gridOptions.delete')}
                      </MenuItem>
                    }



                  </span>

                }


              </Menu>
            </>
        }

      </Box>

    </Box>
  );
}

DatagridOptions.defaultProps = {
  item: {},
  isVetCase: true,
  isExam: false
}
export default DatagridOptions