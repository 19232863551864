export const currToSymbol = (currency = 'USD') => {
    const symbol = Intl.NumberFormat('en', {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency
    })
        .formatToParts(0)
        .filter(part => part.type === 'currency')
        .map(part => part.value)
        .join('')

    return symbol
}