import axios from "axios";
import axiosInstance from "src/utils/axios";

export class Ecg {
    static saveEcgLabel(data) {
        return axiosInstance.post('/ecg_labels', data)
    }

    static deleteEcgLabel(id) {
        return axiosInstance.delete(`/ecg_labels/${id}`)
    }

    static patchEcgLabel(id, data) {
        return axiosInstance.patch(`/ecg_labels/${id}`, data, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })
    }

    static saveEcgSample(data) {
        return axiosInstance.post('/exam_equimetre_samples', data)
    }
    static patchEcgSample(id, data) {
        return axiosInstance.patch(`/exam_equimetre_samples/${id}`, data, {
            headers: { "Content-Type": "application/merge-patch+json" },
        })
    }
    static deleteEcgSample(id) {
        return axiosInstance.delete(`/exam_equimetre_samples/${id}`)
    }

    static async getPics(link) {
        try {
            let data = await fetch(link)
            let t = await data.text()

            const array = t.trim().split(/\r?\n/);

            let transformedArray = array.map(val => parseInt(val))

            return transformedArray
        } catch (err) {
            return err
        }
    }

    static updatePics(uri, str) {
        return axios.put(uri, str)
    }

    static markAsReviewed(data) {
        return axiosInstance.post('/ecg_reviews', data)
    }
}