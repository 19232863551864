import React, { useEffect, useState } from 'react'
import Page from '../Page'
import { Autocomplete, Box, Button, Card, CardContent, CardHeader, Container, Divider, Grid, Skeleton, Stack, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { capitalize, get, lowerCase } from 'lodash'
import { Icon } from '@iconify/react'
import { Controller, useForm } from 'react-hook-form'
import useLocales from 'src/hooks/useLocales'
import { LoadingButton } from '@mui/lab'
import { countries } from 'src/_mock'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { CssBaseline } from '@mui/material';
import { Navigate, useNavigate } from 'react-router'
import { useSubscription } from 'src/hooks/useSubscriptions'
import { useSnackbar } from 'notistack'
import { currToSymbol } from 'src/utils/currency'
import PaymentSuccess from './PaymentSuccess'

function SubscriptionForm() {
    const { translate } = useLocales()
    const user = useSelector(state => state.user.user)
    const { sendSubscription, getSubscriptionById, isLoadingPlans, userData, getSubscriptionByIdForPayment } = useSubscription({
        enableQuery: true,
        user_email: user?.email,
        user_lang: user?.lang,
        enableSubscriptionsQuery: false
    })
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const { register, handleSubmit, formState: { errors }, control, watch, getValues } = useForm({
        defaultValues: {
            email: userData?.email ?? "",
            first_name: userData?.first_name ?? "",
            last_name: userData?.last_name ?? "",
            address_line1: userData?.address_line1 ?? "",
            address_line2: userData?.address_line2 ?? "",
            postal_code: userData?.postal_code ?? "",
            city: userData?.city ?? "",
            country: countries.find(country => lowerCase(country.label) === lowerCase(userData?.country)) ?? "",
            company: userData?.company ?? "",
            phone: ""
        }
    })

    const [payment_link, setPaymentLink] = useState(null)

    //Get user Selected Subscriptions
    const selectedPlan = useSelector(state => state.user.subscriptionPlan)
    const [defaultCountry, setDefaultCountry] = useState('FR')
    const [selectedCountry, setSelectedCountry] = useState(null)
    const watchCountry = watch('country')

    const [helperText, showHelperText] = useState(false)
    const [paymentSuccess, setIsPaymentSuccess] = useState(false)


    const [subscription, setSubscription] = useState(null)

    const getSelectedCountry = (country) => {
        return countries.find(c => c.label === country)
    }

    useEffect(() => {
        if (watchCountry) {
            let sc = getSelectedCountry(watchCountry)
            setDefaultCountry(sc?.code ?? 'FR')
            setSelectedCountry(sc)
        }

    }, [watchCountry])

    const onSubmit = async (data) => {

        let payload = {
            ...data,
            user: user?.['@id'],
            "periodicity": get(selectedPlan, 'plan.billing_period'),
            "currency": get(selectedPlan, 'selectedPrice.currency'),
            "country": get(data, 'country.label')
        }

        //if The number is not valid remove it
        if (!matchIsValidTel(data?.phone)) {
            delete payload['phone']
        }



        try {
            let response = await sendSubscription.mutateAsync(payload)


            setSubscription(response.data)

            if ('stripe_payment_link' in response.data && response.data?.stripe_payment_link) {
                setPaymentLink(response.data.stripe_payment_link)
                return
            }
            try {
                let { data: subscription } = await getSubscriptionById.mutateAsync(response.data.id)

                setPaymentLink(subscription.stripe_payment_link)

            } catch (error) {
                enqueueSnackbar(translate('general.error'), { variant: 'error' })
            }


        } catch (error) {
            enqueueSnackbar(translate('general.error'), { variant: 'error' })
        }
    }

    const checkSubscription = () => {
        showHelperText(true)
        getSubscriptionByIdForPayment.mutateAsync(subscription?.id)
            .then(result => {
                setIsPaymentSuccess(true)
            }).catch(err => {
                enqueueSnackbar(translate('general.error'), { variant: 'error' })
            })
    }


    //if not plan selected
    if (!selectedPlan) {
        return <Navigate to={'/settings/account?tab=settings.subscriptions'}></Navigate>
    }



    return (
        <Page title='Payment'>
            <CssBaseline />
            <Container maxWidth='md'>
                {
                    isLoadingPlans ?
                        <Skeleton sx={{ borderRadius: 2 }} variant='rectangular' width={'100%'} height={'600px'}></Skeleton>
                        : paymentSuccess ?
                            <PaymentSuccess></PaymentSuccess> :
                            <Card>
                                <CardContent>

                                    {/* Informations Header */}
                                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>

                                        <Typography display={'flex'} alignItems={'center'} variant='h5' gap={1}>
                                            {translate('subscription.hint', {
                                                price: `${get(selectedPlan, 'selectedPrice.prix', 0)} ${currToSymbol(get(selectedPlan, 'selectedPrice.currency'))}`,
                                                period: translate(get(selectedPlan, 'plan.billing_period'))
                                            })}
                                            <Typography variant='caption' color={'GrayText'}>{`( ${translate('subscription.horsTva')} )`}</Typography>
                                        </Typography>
                                        <Button onClick={() => navigate('/settings/account?tab=settings.subscriptions')} startIcon={<Icon icon={'ic:twotone-arrow-back'}></Icon>}>{translate('team.edit')}</Button>
                                    </Box>
                                    <Divider sx={{ my: 2 }}></Divider>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid container spacing={2} >
                                            <Grid item md={12} xs={12}>
                                                <TextField fullWidth error={errors?.email} label={translate('auth.email')} {...register("email", {
                                                    required: true, pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    }
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <TextField fullWidth error={errors?.company} label={translate('subscription.company')} {...register("company", {
                                                    required: true
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <TextField fullWidth error={errors?.first_name} label={translate('auth.firstname')} {...register("first_name", {
                                                    required: true
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <TextField fullWidth error={errors?.last_name} label={translate('auth.lastname')} {...register("last_name", {
                                                    required: true
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <TextField fullWidth error={errors?.address_line1} label={translate('user.address') + ' 1'} {...register("address_line1", {
                                                    required: true
                                                })}></TextField>
                                            </Grid>


                                            <Grid item md={12} xs={12}>
                                                <TextField fullWidth error={errors?.address_line2} label={translate('user.address') + ' 2'} {...register("address_line2", {
                                                    required: false
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <TextField fullWidth type='number' error={errors?.postal_code} label={translate('user.zipCode')} {...register("postal_code", {
                                                    required: true
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={6} xs={12}>
                                                <TextField fullWidth error={errors?.city} label={translate('user.city')} {...register("city", {
                                                    required: true
                                                })}></TextField>
                                            </Grid>

                                            <Grid item md={12} xs={12}>
                                                <Controller
                                                    name="country"
                                                    control={control}
                                                    rules={{
                                                        required: "Country is required"
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <Autocomplete
                                                            {...field}
                                                            options={countries}
                                                            getOptionLabel={(option) => option.label || ""}
                                                            isOptionEqualToValue={(option, value) => option.code === value.code}
                                                            onChange={(_, value) => field.onChange(value?.label || "")} // Update field value
                                                            value={field.value || null} // Ensure value is controlled

                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label={translate('user.country')}
                                                                    error={!!fieldState.error} // Use fieldState.error to indicate error state
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            </Grid>



                                            {
                                                selectedCountry?.code === 'US' && <Grid item md={6} xs={12}>
                                                    <TextField fullWidth error={errors?.state_code_us} type='number' label={translate('subscription.state')} {...register("state_code_us", {
                                                        required: selectedCountry?.code === 'US'
                                                    })}></TextField>
                                                </Grid>
                                            }

                                            <Grid item md={6} xs={12}>
                                                <TextField fullWidth error={errors?.vat_number} label={translate('subscription.tva')} {...register("vat_number", {
                                                    required: false
                                                })}></TextField>
                                            </Grid>




                                            <Grid item md={12} xs={12}>
                                                <Controller
                                                    name="phone"
                                                    control={control}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <MuiTelInput
                                                                fullWidth
                                                                {...field}
                                                                label={translate('user.phoneNumber')}
                                                                defaultCountry={defaultCountry} // You can set the default country
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error ? fieldState.error.message : ''}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Grid>



                                            <Grid item md={12} xs={12} >
                                                <LoadingButton disabled={payment_link} loading={sendSubscription.isLoading || getSubscriptionById.isLoading} fullWidth variant='contained' type='submit'>{translate('next')}</LoadingButton>
                                                <a onClick={checkSubscription} href={payment_link} target="_blank" rel="noopener noreferrer">
                                                    <LoadingButton loading={getSubscriptionByIdForPayment.isLoading} sx={{ mt: 2 }} fullWidth disabled={!payment_link} variant='contained'>{translate('subscription.pay_now')}</LoadingButton>
                                                </a>
                                                <Box textAlign={'center'}>
                                                    {
                                                        helperText && <Typography variant='caption' textAlign={'center'}>{translate('subscription.waitingForPaymentHint')}</Typography>
                                                    }
                                                </Box>


                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                            </Card>
                }

            </Container>
        </Page>
    )
}

export default SubscriptionForm