import { useEffect } from "react"
import { useMutation } from "react-query"
import { Examen } from "src/classes/Examen"

export const useExamTracker = () => {

    const sendTrackData = useMutation((data) => Examen.sendTrackData(data))


    const sendAnalytics = async (exam_id) => {
        try {
            await sendTrackData.mutateAsync({ exam: exam_id })

        } catch (error) {
            console.warn('Failed to send Analytic data to server for exam : ' + exam_id);

        }
    }

    return {
        sendAnalytics
    }
}