import { Alert, Box, Button } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import useLocales from 'src/hooks/useLocales'

function EndingSoonBanner() {
    const { user } = useSelector(state => state.user)
    const [open, setOpen] = useState(true)
    const { translate } = useLocales()
    const navigate = useNavigate()
     if (!user?.ecg_trial_end_date) return <></>

    if (!open) return <></>
    return (
        <Alert
            action={
                <Button onClick={() => navigate('/settings/account?tab=settings.subscriptions')} size='small'>{translate('subscription.subToDvet')}</Button>

            }
            severity='warning' sx={{ position: 'fixed', top: '60px', width: '100vw', left: 0, zIndex: 100,display:'flex',alignItems:'center',justifyContent:'space-between' }}>
            {translate('general.trialEnd', { date: dayjs(user?.ecg_trial_end_date).format('DD/MM/YYYY') })}

        </Alert>
    )
}

export default EndingSoonBanner