import { useTranslation } from "react-i18next";
// '@mui
import { enUS, deDE, frFR } from "@mui/material/locale";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "src/redux/slices/user";
import { frFR as french, enUS as english } from '@mui/x-data-grid'
import { useEffect, useState } from "react";
// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
    icon: "flagpack:gb-ukm",
  },
  /*   {
      label: "German",
      value: "de",
      systemValue: deDE,
      icon: "flagpack:de",
    }, */
  {
    label: "French",
    value: "fr",
    systemValue: frFR,
    icon: "flagpack:fr",
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const accessToken = localStorage.getItem('accessToken')
  const { user } = useSelector(state => state.user)
  const [currentLang, setCurrentLang] = useState(null)/*  LANGS.find((_lang) => _lang.value === langStorage) || LANGS[0] */;
  const dispatch = useDispatch()

  useEffect(() => {
    let lang = null
    if (user?.lang) {
      lang = LANGS.find((_lang) => _lang.value === user?.lang) ?? LANGS[0]
      setCurrentLang(lang)
    } else {
      lang = LANGS.find((_lang) => _lang.value === langStorage) ?? LANGS[0]
      setCurrentLang(lang)
    }
    i18n.changeLanguage(lang.value)
  }, [user])

  const updateUserLang = (lang) => {
    dispatch(updateUser(user?.id, { lang }))
  }
  const handleChangeLanguage = (newlang) => {
    if (accessToken) {
      updateUserLang(newlang)
    }else{

      //Find lang from localStorage or fallback to lang at index 0
      let lang = LANGS.find((_lang) => _lang.value === newlang) ?? LANGS[0]
      setCurrentLang(lang)
    }
    i18n.changeLanguage(newlang);
    
    localStorage.removeItem("i18nextLng")
    localStorage.setItem("i18nextLng",newlang)

  };

  const dataGridLocales = () => {
    switch (currentLang?.value) {
      case 'fr':
        return french.components.MuiDataGrid.defaultProps.localeText
      case 'en':
        return english.components.MuiDataGrid.defaultProps.localeText
      default:
        return french.components.MuiDataGrid.defaultProps.localeText

    }
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
    updateUserLang,
    dataGridLocales
  };
}
