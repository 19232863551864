import { Icon } from '@iconify/react'
import { CenterFocusWeak, FullscreenExit, NavigateBefore, NavigateNext, ZoomIn, ZoomOut } from '@mui/icons-material'
import { Box, FormControl, IconButton, InputLabel, OutlinedInput, TextField, Tooltip } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useCallback } from 'react'
import { memo } from 'react'
import { useEffect } from 'react'

EcgChartNavigator.defaultProps = {
    zoom: false,
    enableTriggerZoom: true
}
function EcgChartNavigator({ autoZoomValue, onAutoZoomChange, zoom, onBack, onNext, pourcentage, onZoomIn, onZoomOut, onCustomZoom, enableTriggerZoom, resetZoom }, ref) {
    useImperativeHandle(ref, () => ({
        getSeconds: () => autoZoomValue
    }), [autoZoomValue])
    return (
        <div id='chartNavigator'>
            <Box display={'flex'} justifyContent={'end'} alignItems={'center'} >
                <IconButton onClick={onZoomIn}>
                    <Icon icon={'bytesize:zoom-in'}></Icon>
                </IconButton>

                <OutlinedInput
                    id='sec'
                    value={autoZoomValue}
                    onChange={e => onAutoZoomChange(e.target.value)}
                    onBlur={e => !autoZoomValue && onAutoZoomChange(10)}
                    type='number'
                    size='small'
                    placeholder='Seconds'
                    sx={{ width: '150px' }}
                    endAdornment={
                        <Tooltip title={`Auto Zoom ${autoZoomValue} sec`} placement='bottom'>
                            <IconButton onClick={() => onCustomZoom(autoZoomValue)}>
                                <Icon icon={'bytesize:zoom-reset'}></Icon>
                            </IconButton>
                        </Tooltip>
                    }
                >


                </OutlinedInput>

                <IconButton disabled={!zoom} onClick={onZoomOut}>
                    <Icon icon={'bytesize:zoom-out'}></Icon>
                </IconButton>

                <IconButton id='left' disabled={!zoom} onClick={() => onBack(pourcentage)}>
                    <Icon icon={'fluent-mdl2:navigate-back'}></Icon>
                </IconButton>

                <IconButton id='right' disabled={!zoom} onClick={() => onNext(pourcentage)}>
                    <Icon icon={'fluent-mdl2:navigate-back-mirrored'}></Icon>
                </IconButton>

                <Tooltip title='Reset Zoom' placement='bottom'>
                    <IconButton id='right' disabled={!zoom} onClick={() => resetZoom()}>
                        <Icon icon={'material-symbols:zoom-in-map-rounded'}></Icon>
                    </IconButton>
                </Tooltip>
            </Box>
        </div>
    )
}

export default forwardRef(EcgChartNavigator)