
import { Chip } from '@mui/material';
import { get, uniq, uniqBy, isEmpty, capitalize } from 'lodash';
import dayjs from 'dayjs';
import DatagridOptions from 'src/components/dashboard/DatagridOptions';
import { gridKeys } from 'src/enums/dashboard.enums';
import { dateComparator } from './examDashboard';
import { AllInboxRounded } from '@mui/icons-material';
import { ExamExtractor } from './ExamExtractor';
import { t } from 'i18next';

import UTC from 'dayjs/plugin/utc'

dayjs.extend(UTC)

//Dashboard Widget 
export const dashboardWidget = [
    {
        title: 'general.exams',
        color: 'primary',
        icon: <AllInboxRounded />,
        key: 'EXAM_WITH_NO_VETCASE',
        link: '/exams'
    },
    /*     {
            title: 'cards.externalLink',
            color: 'info',
            icon: <FolderCopyOutlined />,
            key: 'USER_VET_CASES',
            link: '/exams/externalCases'
    
        }, */
    /*     {
            title: 'cards.client_exams_not_consulted',
            color: 'warning',
            icon: <FolderSharedIcon />,
            key: 'KE',
            link: ''
    
    
        }, */
]
//End of dashboard widget

//Table Header for vet Cases
export const columns = [
    { field: gridKeys.horse, headerName: gridKeys.horse, flex: 1, valueGetter: (params) => get(params, 'row.horse.name', '-').toUpperCase(), sortable: false, filterable: false },
    { field: gridKeys.folder, headerName: gridKeys.folder, valueGetter: (params) => get(params, 'row.case_number', ''), flex: 1, sortable: false, filterable: false },
    { field: gridKeys.reason, headerName: gridKeys.reason, flex: 1, renderCell: (params) => <Chip label={get(params, 'row.reason', '-')} />, sortable: false, filterable: false },
    { field: gridKeys.client, headerName: gridKeys.client, flex: 1, valueGetter: (params) => get(params, 'row.horse.owner', get(params, 'row.client', '')), sortable: false, filterable: false },
    { field: gridKeys.vet, headerName: gridKeys.vet, flex: 1, valueGetter: (params) => get(params, 'row.vet_name', ''), sortable: false, filterable: false },
    {
        field: gridKeys.tool, headerName: gridKeys.lastUsedTool, flex: 1, renderCell: (params) => {
            let outil = getOutils(get(params, 'row.last_exam.@type', null))
            let colorOutils = getOutilsColor(get(params, 'row.last_exam.@type', null))
            return <Chip label={outil} sx={{ backgroundColor: colorOutils.background, color: colorOutils.text,borderRadius:1 }} size='small' />
        }, sortable: false, filterable: false
    },
    {
        field: gridKeys.team, headerName: gridKeys.team, flex: 1, valueGetter: (params) => {
            let isInvited = params.row?.current_user_invited
            return isInvited ? t('general.invited') : get(params, 'row.team.name', '-')
        }, sortable: false, filterable: false
    },
    { field: gridKeys.creation, type: 'date', sortComparator: dateComparator, headerName: gridKeys.laseExamDate, flex: 1, valueGetter: (params) => ExamExtractor.getLastExam(get(params, 'row.last_exam', null)), sortable: true, filterable: false },
    { field: gridKeys.actions, headerName: gridKeys.actions, renderCell: (params) => <DatagridOptions enableEdit={params.row?.current_user_invited ? false : true} isVetCase item={params.row} />, sortable: false, filterable: false }
];

//Filter fields for vet cases
export const filters = [
    { field: gridKeys.folder, headerName: gridKeys.folder, data: [], value: '', path: 'case_number' },
    { field: gridKeys.horse, headerName: gridKeys.horse, data: [], value: '', path: 'horse.name' },
    { field: gridKeys.client, headerName: gridKeys.client, data: [], value: '', path: 'horse.owner' },
    { field: gridKeys.tool, headerName: gridKeys.tool, data: [], value: '', path: 'last_exam.@type' },
    { field: gridKeys.team, headerName: gridKeys.team, data: [], value: '', path: 'team.name' },
    { field: gridKeys.reason, headerName: gridKeys.reason, data: [], value: '', path: 'reason' },
    { field: gridKeys.vet, headerName: gridKeys.vet, data: [], value: '', path: 'vet_name' },
    { field: gridKeys.creation, headerName: gridKeys.date, value: '', path: 'last_exam.timestamp_start.unix', hidden: true },
];


export function getOutils(outils) {
    switch (outils) {
        case 'ExamEquisym':
            return 'Equisym'
        case 'ExamEquimetre':
            return 'Equimetre'
        default:
            return ""
    }
}
export function getOutilsColor(outils) {
    switch (outils) {
        case 'ExamEquisym':
            return { background: '#BF40BF', text: 'white' }
        case 'ExamEquimetre':
            return { background: '#4BB543', text: 'white' }
        default:
            return { background: '#E5E8EB', text: 'black' }
    }
}
export function getOutilsTextColor(outils) {
    switch (outils) {
        case 'ExamEquisym':
            return { color: '#BF40BF' }
        case 'ExamEquimetre':
            return { color: '#4BB543' }
        default:
            return { color: '#E5E8EB' }
    }
}


export function populateFilters(exams, Filters) {
    let temp = [...Filters]

    temp.forEach(filter => {
        return filter.data = uniq(exams?.map(exam => get(exam, filter.path, '') || ''))
    })

    return temp
}

export function applyFilters(_filters, data, uniqKey = 'id') {

    let filtered = data
    _filters.forEach(filter => {
        switch (filter.field) {
            case gridKeys.folder:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.horse:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.client:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.tool:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.team:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.reason:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.stable:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.vet:
                if (!filter.value) return
                filtered = filtered.filter(e => get(e, filter.path) === filter.value)
                break;
            case gridKeys.creation:
                if (!filter.value) return
                filtered = filtered.filter(e => {
                    return dayjs.unix(get(e, filter.path)).format('DD/MM/YYYY') === dayjs(filter.value).format('DD/MM/YYYY')
                })
                break;
            default:
                return []
        }
    })


    return filtered
}

export function isFiltersEmpty(_filters) {
    return _filters.every(filter => isEmpty(filter.value))
}

export function formatDate(value, format = 'DD/MM/YYYY HH:mm') {

    if (!value) return ''
    return  dayjs.utc(dayjs.unix(value)).format(format)
}

export function formatCreationDate(value, format = 'DD/MM/YYYY HH:mm') {

    if (!value) return ''
    return  dayjs.utc(value).format(format)
}


export function injectI18N(array = [], key, translator, prefix = 'grid.') {
    const newArr = []
    try {
        array.forEach(el => {

            let temp = Object.assign({}, el)
            temp[key] = capitalize(translator(`${prefix}${temp[key]}`))

            newArr.push(temp)

        })
        return newArr
    } catch (err) {
        console.log({ err });
        return array

    }

}


//Order vet case by last exam 

export function getLastExamFromCases(vet_cases = []) {
    if (isEmpty(vet_cases)) return null

    let formatedCases = vet_cases.map(e => get(e, 'last_exam', null))


    formatedCases.sort((element1, element2) => {
        return dayjs.unix(element1?.timestamp_start?.unix).isBefore(dayjs.unix(element2?.timestamp_start?.unix)) ? 1 : -1
    })

    return formatedCases[0]


}