import { Alert, Box, Button, Chip, CircularProgress, Divider, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import DatagridOptions from 'src/components/dashboard/DatagridOptions'
import VetCasesModal from 'src/components/dashboard/VetCasesModal'
import Error from 'src/components/global/Error'
import ShowMore from 'src/components/global/ShowMore'
import AddVetCaseModal from 'src/components/horses/AddVetCaseModal'
import { gridKeys } from 'src/enums/dashboard.enums'
import useLocales from 'src/hooks/useLocales'
import { setRoute, setTabsVisibility } from 'src/redux/slices/display'
import { setExamCases, setExamCasesModal, setSelectedCasesHorse } from 'src/redux/slices/examen'
import { getHorseExams } from 'src/redux/slices/horse'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { injectI18N } from 'src/utils/dashboard'
import { uncategorizedExamsColumns } from 'src/utils/examDashboard'
import { ExamExtractor } from 'src/utils/ExamExtractor'
import ExamOptions from './ExamOptions'
import { Examen } from 'src/classes/Examen'
import { useSnackbar } from 'notistack'
import AddExamToCase from 'src/components/dashboard/AddExamToCase'
import SelectCaseForReport from 'src/components/dashboard/SelectCaseForReport'
import Iconify from 'src/components/Iconify'
import dayjs from 'dayjs'

function ExamsView() {
    const { horse, exams, folders } = useSelector(state => state.horse)
    const { translate, dataGridLocales } = useLocales()
    const dispatch = useDispatch()
    const { userInOrganisation, user } = useSelector((state) => state.user);
    const { examCases, examCasesModal } = useSelector((state) => state.examen);
    const [vetModal, setVetModal] = useState(false)
    const navigate = useNavigate()
    let userTeams = get(user, 'user_in_teams', [])
    const [addToCaseModal, setAddToCaseModal] = useState(false)
    const [casesModal, setCasesModal] = useState(false)
    const [selectedExam, setSelectedExam] = useState(undefined)
    const _teams = userTeams.filter(
        (_team) => _team?.team?.organisation?.id === userInOrganisation?.organisation?.id
    )
    const addExamMutation = useMutation((data) => Examen.addExamToVetCase(data))
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        dispatch(setRoute('1'))
        dispatch(setTabsVisibility(true))

    }, [])

    const openNewVetCaseModal = () => {
        setVetModal(true)
    }

    const closeNewVetCaseModal = () => {
        setVetModal(false)
    }
    const isEnabledSuiviLongitudinal = useMemo(() => {
        return exams.some(exam => get(exam, '@type') === "ExamEquisym")
    }, [exams])



    const query = useQueryClient()
    const queryState = query.getQueryState(['horse_exams', horse?.external_id]);



    const columns = [
        { field: gridKeys.horse, headerName: translate('grid.' + gridKeys.horse), flex: 1, valueGetter: (params) => ExamExtractor.getHorseName(params.row), sortable: false, filterable: false },
        { field: gridKeys.client, headerName: translate('grid.' + gridKeys.client), flex: 1, valueGetter: (params) => ExamExtractor.getHorseOwner(params.row), sortable: false, filterable: false },
        { field: gridKeys.description, headerName: translate('grid.' + gridKeys.description), flex: 1, valueGetter: (params) => ExamExtractor.getDescription(params.row), sortable: false, filterable: false },
        { field: gridKeys.creation, headerName: translate('grid.' + gridKeys.creation), flex: 1, type: 'date', sortable: false, filterable: false, valueGetter: (params) => ExamExtractor.getLastExam(params.row) },
        {
            field: gridKeys.review, headerName: translate('grid.' + gridKeys.review), flex: 1, renderCell: (params) => {

                let mappedReviews = params.row.exam_in_vet_cases.map(v => v?.ecg_reviews ?? [])


                let reviewd = mappedReviews.some(rev => !isEmpty(rev))

                let reviews = mappedReviews.filter(r => !isEmpty(r))

                let reviewer = get(reviews, '[0][0].reviewed_by', '')
                let date = get(reviews, '[0][0].review_date') ? dayjs(get(reviews, '[0][0].review_date')).format('DD-MM-YYYY ') : ''
              
              
                return (
                    <Tooltip placement='top' title={reviewd ? translate('ecg.reviewed', {
                        user: reviewer?.full_name,
                        date: date
                    }) : ''} arrow>
                        <Chip
                            size='small'
                            variant='filled'
                            label={reviewd ? translate('reviewed') : translate('notReviewed')}
                            sx={{ borderRadius: 1, backgroundColor: reviewd ? 'rgba(135, 211, 124,0.8)' : 'rgba(241, 130, 141)', color: 'white' }}
                        />
                    </Tooltip>)
            }
        },
        {
            field: 'vet_cases',
            flex: 1,
            headerName: translate('nav.folders'),
            renderCell: (param) => {

                let folders = param.row?.exam_in_vet_cases.map(cases => cases?.vet_case?.case_number ?? translate('general.unamed_folder'))

                return (
                    <span onClick={(e) => {
                        e.stopPropagation()

                        dispatch(setExamCases(param.row?.exam_in_vet_cases.map(e => e.vet_case) ?? []))
                        dispatch(setExamCasesModal(true))
                        dispatch(setSelectedCasesHorse(horse))
                    }}>
                        <ShowMore items={folders} maxLength={1} renderItem={(item) => <Chip size='small' variant='filled' color='default' label={item}></Chip>
                        }></ShowMore ></span>)
            },
            sortable: false
        },
        {
            field: gridKeys.actions, headerName: gridKeys.actions, width: 100, renderCell: (params) =>
                <ExamOptions createRepport={createRepport} addToCase={addToCase} exam={params.row}></ExamOptions>,
            sortable: false, filterable: false, align: 'center'
        }

    ]

    const createRepport = (exam) => {
        let horseId = ExamExtractor.getHorseId(exam)
        let examId = exam?.external_id
        let vetCases = exam?.exam_in_vet_cases ?? []

        if (vetCases.length === 1) {
            navigate(PATH_DASHBOARD.horses.createNewReport(horseId, examId, vetCases.at(0)?.['@id']))
        } else {
            setSelectedExam(exam)
            setCasesModal(true)

        }


    }
    const addToCase = (exam) => {
        setSelectedExam(exam)
        setAddToCaseModal(true)
    }

    const addExamToCase = (caseId) => {
        addExamMutation.mutateAsync({
            'vet_case': caseId,
            'exam': get(selectedExam, '@id')
        }).then(res => {
            enqueueSnackbar(translate('snackbar.caseExamCreateSuccess'))
            query.refetchQueries(['horse_exams', ExamExtractor.getHorseId(selectedExam)])
            query.refetchQueries(['vet_case', ExamExtractor.getHorseId(selectedExam)])
            setAddToCaseModal(false)
        }).catch(err => {
            enqueueSnackbar(translate('general.error'), { variant: 'error' })

        })
    }

    //HandleCellClick
    const handleCellClick = (param) => {
        let exam = param.row



        if (param.field !== 'actions') {

            let horseId = param.row?.horse?.external_id

            if (!horseId) {
                return
            }


            let examType = ExamExtractor.getExamType(exam)
            let horse = ExamExtractor.getHorseId(exam)
            let externalId = exam.external_id
            if (examType === 'Equisym') {
                navigate(PATH_DASHBOARD.horses.examenView(
                    horse,
                    externalId,
                    get(exam, 'exam_in_vet_cases[0].vet_case.id', null),

                ))
            }

            if (examType === 'Equimetre') {
                navigate(PATH_DASHBOARD.horses.examenEquimetreView(
                    horse,
                    externalId,
                    get(exam, 'exam_in_vet_cases[0].vet_case.id', null),

                ))
            }


        }
    }

    //Render
    if (queryState?.isLoading || queryState?.isFetching) {
        return (
            <Box display='flex' mt={2} justifyContent='center' alignItems='center' marginTop={3}>
                <CircularProgress />
            </Box>
        )
    }

    if (queryState?.isError) {
        return <Error onClick={() => query.invalidateQueries({ queryKey: ['horse_exams_', horse?.external_id] })} />
    }

    return (
        <>

            {
                isEmpty(folders) && <Alert
                    action={
                        <Button
                            onClick={openNewVetCaseModal}
                            color="inherit" size="small">
                            {translate('addCase.add')}
                        </Button>
                    }
                    sx={{ my: 2 }}
                    severity='info'>{translate('horse.createFolder', { horse: horse?.name })}</Alert>
            }
            {
                isEnabledSuiviLongitudinal && <Box display={'flex'} justifyContent={'end'} my={1}>
                    <Button onClick={() => navigate(PATH_DASHBOARD.horses.followLongitude(horse?.external_id, null))} color='darkGray' variant='outlined' startIcon={<Iconify icon='hugeicons:chart-line-data-01' />}>{translate('suiviLongitude')}</Button>
                </Box>
            }

            <DataGrid
                sx={{ mt: 2 }}
                columns={columns}
                rows={exams}
                onCellClick={(params) => handleCellClick(params)}
                getRowId={(row) => row?.external_id}
                autoHeight
                localeText={dataGridLocales()}
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
            />
            <AddVetCaseModal
                onRequestRefresh={() => {
                    query.invalidateQueries({ queryKey: ['vet_case', horse?.external_id] })
                    query.invalidateQueries({ queryKey: ['horse_exams_', horse?.external_id] })
                }}
                teams={_teams}
                horses={[horse]}
                open={vetModal}
                defaultHorse={horse}
                handleClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        closeNewVetCaseModal()
                    }
                }} />

            <AddExamToCase
                open={addToCaseModal}
                handleClose={() => {
                    setAddToCaseModal(false)
                    setSelectedExam(undefined)
                }}
                items={[]}
                loading={addExamMutation.isLoading}
                onCancel={() => setAddToCaseModal(false)}
                title={translate('modal.addToCase')}
                handleSubmit={addExamToCase}
                item={selectedExam}
                defaultHorse={ExamExtractor.getHorse(selectedExam)}
                skipAddToVet={() => {
                    setAddToCaseModal(false)
                    setSelectedExam(undefined)
                }}
            />
            <SelectCaseForReport
                open={casesModal}
                handleClose={() => {
                    setCasesModal(false)
                    setSelectedExam(undefined)
                }}
                exam={selectedExam}
            >

            </SelectCaseForReport>
        </>

    )
}

export default ExamsView