import React, { useMemo } from 'react'
import { memo } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Tooltip } from '@mui/material';
import { MoreVert, VisibilityOutlined } from '@mui/icons-material';
import useLocales from 'src/hooks/useLocales';
import { dateComparator, dateComparatorDesc } from 'src/utils/examDashboard';
import dayjs from 'dayjs';
import { get, isEmpty, toNumber } from 'lodash';
import { formatDate } from 'src/utils/dashboard';
import Duration from 'dayjs/plugin/duration'
import { Icon } from '@iconify/react';

dayjs.extend(Duration)

function LabelsArray({ plotBands, onSelect, loading }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [imageLink, setImageLink] = React.useState(null);
    const [openModal, setOpenModal] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const sortedPlots = useMemo(() => {
        return isEmpty(plotBands) ? [] : [...plotBands].sort((a, b) => dateComparatorDesc(
            formatDate(dayjs(a?.creation_date).unix()), formatDate(dayjs(b?.creation_date).unix())))
    }, [plotBands])

    const { translate, dataGridLocales } = useLocales()
    const COLUMNS = [

        {
            field: 'label', headerName: translate('grid.name'), flex: 1
        },
        {
            field: 'index_start', headerName: translate('label.start'), flex: 1, renderCell: (params) => dayjs.duration(toNumber(params.row.index_start)).format('HH:mm:ss.SSS')

        },
        {
            field: 'index_end', headerName: translate('label.end'), flex: 1, renderCell: (params) => dayjs.duration(toNumber(params.row.index_end)).format('HH:mm:ss.SSS')
        },
        {
            field: 'peaks_count', headerName: translate('ecg.numberOfPics'), flex: 1
        },
        {
            field: 'average_speed', headerName: translate('grid.vm'), flex: 1, renderCell: (params) => {
                return (Math.round(params.value) || 0) + ' Km/h';
            },
        },
        {
            field: 'average_hr', headerName: translate('grid.bpm'), flex: 1, renderCell: (params) => {
                return Math.round(params.value) + ' Bpm';
            },
        },
        {
            field: 'ecg_sample', headerName: translate('grid.samples'), flex: 1, renderCell: (params) => get(params.row, 'ecg_sample.name')
        },
        {
            field: 'comment', headerName: translate('grid.comment'), flex: 1
        },
        {
            field: 'link', headerName: translate('grid.actions'), flex: 1, align: 'center', renderCell: (params) =>
                <Box display={'flex'} gap={0.8} flexWrap={'wrap'} alignItems={'center'}>
                    <Tooltip title={translate('grid.see_in_graph')} arrow>
                        <IconButton onClick={() => onSelect(params.row)} size='small'>
                            <Icon icon='ci:chart-line'></Icon>
                        </IconButton>
                    </Tooltip>
                    {
                        params.row?.screenshot_url && <Tooltip title={translate('preview')} arrow>
                            <IconButton onClick={() => {
                                setImageLink(params.row.screenshot_url)
                                setOpenModal(true)
                            }} size='small'>
                                <Icon icon='ic:round-image-search'></Icon>
                            </IconButton>
                        </Tooltip>
                    }
                </Box >
            // <div>
            //     <IconButton
            //         id="basic-button"
            //         aria-controls={open ? 'basic-menu' : undefined}
            //         aria-haspopup="true"
            //         aria-expanded={open ? 'true' : undefined}
            //         onClick={handleClick}
            //     >
            //         <MoreVert></MoreVert>
            //     </IconButton>
            //     <Menu
            //         id="basic-menu"
            //         anchorEl={anchorEl}
            //         open={open}
            //         onClose={handleClose}
            //         MenuListProps={{
            //             'aria-labelledby': 'basic-button',
            //         }}
            //     >
            //         <MenuItem onClick={() => {
            //             handleClose()
            //             onSelect(params.row)
            //         }}>
            //             <ListItemText>
            //                 {translate('grid.see_in_graph')}
            //             </ListItemText>
            //         </MenuItem>
            //         {
            //             params.row?.screenshot_url && <MenuItem onClick={() => {
            //                 handleClose()
            //                 setImageLink(params.row.screenshot_url)
            //                 setOpenModal(true)
            //             }}>
            //                 <ListItemText>
            //                     {translate('preview')}
            //                 </ListItemText>
            //             </MenuItem>
            //         }

            //     </Menu>
            // </div >
        }
    ]
    return (
        <Box sx={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={sortedPlots}
                getRowId={(row) => row?.id}
                localeText={dataGridLocales()}
                loading={loading}
                columns={COLUMNS}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableSelectionOnClick
            />
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box style={style}>
                    <img src={imageLink} style={{ width: '100%', height: '100%' }} alt="" srcset="" />
                </Box>
            </Modal>
        </Box>
    )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    boxShadow: 24,
};
export default memo(LabelsArray)