import { Avatar, Box, Button, Card, CardContent, CardHeader, Chip, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Skeleton, Typography } from '@mui/material'
import { get, isEmpty, upperCase } from 'lodash'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { Examen } from 'src/classes/Examen'
import Iconify from '../Iconify'
import { ExamExtractor } from 'src/utils/ExamExtractor'
import useLocales from 'src/hooks/useLocales'
import { formatCreationDate, getLastExamFromCases, getOutils, getOutilsColor } from 'src/utils/dashboard'
import { AccessTime } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'src/routes/paths'
import { getUserInOrganisationSuccess, setIsOrgChanging, switchToUserInOrganisationFromId } from 'src/redux/slices/user'
import { setHorses } from 'src/redux/slices/blog'
import dayjs from 'dayjs'

function FoldersCard() {
    const { horses } = useSelector(state => state.blog)
    const { user, userInOrganisation } = useSelector(state => state.user)
    const { translate } = useLocales()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const examsQuery = useQuery(['lastCases'], () => Examen.getLastCases(user.id, 10), {
        refetchOnWindowFocus: false,
        staleTime: 20 * 60 * 1000,
        cacheTime: 20 * 60 * 1000,
    })

    const cases = get(examsQuery.data, 'data.hydra:member', [])


    const changeOrganisation = (item) => {



        dispatch(setIsOrgChanging(true))


        let restricted = false


        let org_id = get(item, 'team.organisation.id')


        let org = user.limited_access_organisations.find(org => org.id === org_id)


        if (org) {
            restricted = true
        } else {
            org = user.user_in_organisations.find(e => e?.organisation?.id === org_id)
        }

        switchToUserInOrganisationFromId(org?.id, user, restricted)

        dispatch(setIsOrgChanging(false))


    }


    const handleHorseClick = (folder) => {


        //We change organisation if horse has org in object
        changeOrganisation(folder)

        navigate(PATH_DASHBOARD.horses.folderView(
            folder.horse.external_id,
            folder.id
        ))

    }

    return (
        <Card sx={{ minHeight: '100%', minWidth: '100%' }}>
            <CardContent >
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='h5' p={1}>
                        {translate('nav.folders')}
                    </Typography>

                </Box>

                <List dense sx={{ maxHeight: 200, overflowY: 'scroll' }}>

                    {examsQuery.isLoading || examsQuery.isFetching ? Array.from(Array(2)).map(i => (
                        <ListItem disableGutters key={i}>
                            <Skeleton height={50} width={'100%'}></Skeleton>
                        </ListItem>
                    )) : <>
                        {cases.map(c => (
                            <ListItemButton divider onClick={() => handleHorseClick(c)} key={c.id}>
                                <ListItemAvatar >
                                    <Avatar sx={{ borderRadius: 1, bgcolor: "#FFC107" }}>
                                        <Iconify style={{ color: 'white' }} icon='majesticons:folder-line' ></Iconify>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={c?.case_number ?? translate('general.unamed')}
                                    secondary={
                                        <Box display={'flex'} flexDirection={'row'} gap={1} alignItems={'center'} flexWrap={'wrap'} my={0.5}>
                                            <Chip icon={<Iconify icon={'formkit:time'}></Iconify>} size='small' sx={{ borderRadius: 1,fontSize:12 }} label={formatCreationDate(get(c, 'creation_date'))} color='default'/>
                                            <Chip icon={<Iconify icon={'la:horse-head'}></Iconify>} size='small' sx={{ borderRadius: 1,fontSize:12 }} label={upperCase(get(c, 'horse.name', ''))} color='default'/>
                                            <Chip icon={<Iconify icon={'lucide:hospital'}></Iconify>} size='small' sx={{ borderRadius: 1,fontSize:12 }} label={get(c, 'team.organisation.name')} color='default'/>

                                        </Box>}
                                >

                                </ListItemText>
                            </ListItemButton>
                        ))}
                    </>}

                </List>
            </CardContent>
        </Card>
    )
}

export default FoldersCard