import axiosInstance from "src/utils/axios";

export class Subscription {

  static getBillingInfos(user_email, user_lang) {
    return axiosInstance.get(`/billing-information?email=${user_email}&lang=${user_lang}`)
  }

  static async getCurrencyByIP() {
    try {
      // Fetch IP information from ipwhois free API
      const response = await fetch('https://ipwhois.app/json/');
      const data = await response.json();

      if (data.success) {
        // Get country code
        const countryCode = data.country_code;

        // Map country code to currency
        const currency = data.currency;

        return currency; // This will return currency code like "USD", "EUR", etc.
      } else {
        console.error('API Error:', data.message);
        return null;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  }

  static sendSubscription(data) {
    return axiosInstance.post('/subscriptions', data)
  }

  static async getSubscriptionById(id) {
    let response = await axiosInstance.get(`/subscriptions/${id}`)

    if (!response.data?.stripe_payment_link) {
      throw new Error('Link not found in response');
    }
    return response


  }

  static async getSubscriptionByIdForPayment(id) {
    let response = await axiosInstance.get(`/subscriptions/${id}`)

    if (!response.data?.paid_date) {
      throw new Error('Not paid yet');
    }
    return response


  }


  static getUserSubscriptions(id) {
    console.log({id})
    return axiosInstance.get(`/users/${id}/subscriptions`)
  }
}