import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Box } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { switchToUserInOrganisationFromId } from 'src/redux/slices/user';
import { useNavigate } from 'react-router';
import OrganisationMenu from './OrganisationMenu';
import { useQueryClient } from 'react-query';

function OrgPopover() {

    const { user, userInOrganisation } = useSelector((state) => state.user);

    const navigate = useNavigate();
    const query = useQueryClient()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    let userInOrg = get(user, 'user_in_organisations', []) || []

    const orgs = React.useMemo(() => {
        return !isEmpty(userInOrg) ? userInOrg.filter((_item) => _item.accepted === true) : []
    }, [user])

    const RESTRICTED_ACCESS_ORG = React.useMemo(() => {
        return user?.limited_access_organisations
    }, [user])

    const handleOrgSelect = (event, cliniqueId, restricted) => {
        event.preventDefault();
        switchToUserInOrganisationFromId(cliniqueId, user, restricted)
        query.invalidateQueries()
        navigate('/dashboard')
        handleClose()
    }
    const orgName = React.useMemo(() => {
        return userInOrganisation?.restricted ? get(userInOrganisation, 'name', '') : get(userInOrganisation, 'organisation.name', '')
    }, [userInOrganisation])

    if ((orgs.length + RESTRICTED_ACCESS_ORG.length) > 1) {
        return (
            <div>
                <Box ml={2} sx={{ cursor: 'pointer' }} aria-describedby={id} display={'flex'} alignItems={'center'} gap={1} onClick={handleClick}>
                    <Box display={'flex'} alignItems={'center'} gap={1}>
                        <Avatar sx={{ width: 28, height: 28, padding: '1px' }} >
                            {userInOrganisation?.ghost ? <Iconify icon='mdi:ghost'  ></Iconify> : <Iconify icon='lucide:hospital'  ></Iconify>}
                        </Avatar>
                        <Typography sx={{ color: '#6d6d6d !important' }} variant='body2'>{orgName}</Typography>

                    </Box>

                    <Iconify icon='heroicons:chevron-up-down-20-solid' color='gray' width={15} height={15}></Iconify>
                </Box>
                <Popover
                    id={id}
                    sx={{ top: 10, left: 10 }}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <OrganisationMenu restrictedOrgs={RESTRICTED_ACCESS_ORG} loading={false} cliniques={orgs} onSelect={handleOrgSelect}></OrganisationMenu>

                </Popover>
            </div>
        );
    } else {
        return <></>
    }


}
export default React.memo(OrgPopover)