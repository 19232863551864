import { Box, Button, Card, Checkbox, Divider, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import React from 'react'
import useLocales from 'src/hooks/useLocales'

function TrainingChartToolbar({
  showSpeed,
  handleShowSpeedChange,
  sync,
  onSyncChange,
  hrOrRrValue,
  handleHrOrRrValueChange,
  hrOrRrOptions,
  r_peak_uri,
  isSampleModeActive,
  isEditSampleModeActive,
  onSampleModeChange,
  onEditSampleModeChange,
  showLabels,
  onShowLabelsChange,
  showSamples,
  onShowSamplesChange,

}) {
  const { translate } = useLocales()
  return (
    <>
      <Card sx={{ p: 2 }} >
        <Typography variant="subtitle1" component="h1" paragraph>
          {translate('ecg.display.title')}
        </Typography>
        <Box display={'flex'} alignItems={'center'} gap={3} flexWrap={'wrap'}>
          <FormControlLabel
            value="speed"
            control={
              <Checkbox
                checked={showSpeed}
                onChange={handleShowSpeedChange}
              />
            }
            label={translate('ecg.display.speed')}
          />
          <RadioGroup
            row
            sx={{ m: 0 }}
            value={hrOrRrValue}
            onChange={handleHrOrRrValueChange}
          >
            {hrOrRrOptions.map((option, index) => (
              <div key={option.name}>
                <FormControlLabel
                  key={option.name}
                  value={option.value}
                  control={<Radio disabled={!r_peak_uri} />}
                  label={option.name}
                  sx={{ m: 0 }}
                />
              </div>
            ))}
          </RadioGroup>
          <FormControlLabel
            value="sync"
            control={<Checkbox checked={sync} onChange={e => onSyncChange(e.target.checked)} />}
            label={translate('ecg.display.syncChart')}
          />

          <FormControlLabel
            control={<Checkbox checked={showSamples} onChange={e => onShowSamplesChange(e.target.checked)} />}
            label={translate('ecg.display.showSamples')}
          />

          <FormControlLabel
            control={<Checkbox checked={showLabels} onChange={e => onShowLabelsChange(e.target.checked)} />}
            label={translate('ecg.display.showLabels')}
          />

        </Box>
      </Card>
      <Box display={'flex'} gap={2}>
        <Button disableElevation size="medium" sx={{ color: 'white' }} color={isSampleModeActive ? 'success' : 'primary'} onClick={() => onSampleModeChange(!isSampleModeActive)} variant="contained">{translate('ecg.display.placeSample')} {isSampleModeActive && `(${translate('active')})`} </Button>
        <Button disableElevation size="medium" sx={{ color: 'white' }} color={isEditSampleModeActive ? 'success' : 'primary'} onClick={() => onEditSampleModeChange(!isEditSampleModeActive)} variant="contained">{translate('ecg.display.editSample')} {isEditSampleModeActive && `(${translate('active')})`}</Button>
      </Box>

    </>
  )
}

export default TrainingChartToolbar